.navbar {
  position: sticky;
  top: 10px;
  width: 100%;
  min-height: 15vh;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 90px;
    z-index: 11;
    position: relative;
  }

  /*  button {
    background-color: #fff;
    color: $seconderyColor;
    padding: 0.6rem 0.8rem;
    border-radius: 5px;
    display: none;
    z-index: 11;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    @include responsive-mobile {
      display: flex;
    }
  }*/
  .nav-col1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-ham {
      display: none;
    }
  }

  .nav-col2 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      margin: 0 10px;
    }
  }

  @include responsive-tablet {
    .nav-col1 {
      .nav-ham {
        display: block;
      }
    }

    .nav-col2 {
      display: none;
      &.open {
        display: flex;
      }
      position: absolute;
      top: 100%;
      z-index: 10;
      background-color: #fff;
      width: 100%;
      gap: 5px;
      padding: 10px 15px;
      border-bottom: 2px solid #f1f1f1;
      left: 0;
      flex-direction: column;
      div {
        display: block;
        width: 100%;
        text-align: center;
        margin: 5px 0;
        background-color: #f1f1f1;
        padding: 5px 0;
        border-radius: 5px;
        a {
          width: 100%;
          display: block;
        }
      }
    }
  }
}
