.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}
.input-group {
  display: flex;
  font-size: 2rem;
  color: $seconderyColor;
  flex-direction: column;
  padding: 20px;
  label {
    padding: 20px 0;
  }
  input {
    border: none;
    padding: 20px;
    font-size: 2.5rem;
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    &:active {
      border: none;
    }
    &::placeholder {
      font-size: 2.25rem;
      text-decoration: underline;
      color: rgba(0, 0, 0, 0.3);
    }
    &:focus {
      font-size: 3rem;
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}
