.carousel {
  max-height: 85vh;
  overflow: hidden;
  img {
    width: 100%;

    animation-name: zoomingimg;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  @keyframes zoomingimg {
    from {
      width: 100%;
    }
    to {
      width: 105%;
    }
  }
}
