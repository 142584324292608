.quick-search {
  padding: 20px;
  max-width: 80vw;
  margin: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  position: relative;
  top: -100px;
  background-color: white;
  z-index: 5;
  .quick-search-container {
    display: flex;
    align-items: center;
  }
}
