.btn {
  border: 0;
  background: transparent;
  color: $primaryColor;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  font-family: $fontFamily;
  font-size: 15px;
  &-primary {
    text-transform: uppercase;
    padding: 0.9rem 2rem;
    border-radius: 5rem;
    font-size: 1.1rem;
    background-color: $seconderyColor;
    color: #fff;

    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      width: 100%;
      border-radius: 5rem;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
      background-color: $seconderyColor;
    }

    &:not([disabled]):hover {
      transform: translateY(-3px);
      box-shadow: 0 1rem 2rem rgba($primaryColor, 0.2);

      &::after {
        transform: scaleX(1.3) scaleY(1.4);
        opacity: 0;
      }
    }
  }

  &-secondary {
    text-transform: uppercase;
    color: $seconderyColor;
    border-bottom: 1px solid $seconderyColor;
    font-size: 1rem;
    padding: 5px;

    &:not([disabled]):hover {
      transform: translateY(-3px);
      box-shadow: 0 1rem 2rem rgba($primaryColor, 0.2);
      background-color: $seconderyColor;
      color: #fff;
    }
  }

  &-normal {
    background-color: $seconderyColor;
    padding: 0.6rem 1.5rem;
    color: white;
    width: 40%;
    font-size: 2rem;
    border: none;
    border-radius: 10px;
    &:not([disabled]):hover {
      background-color: $primaryColor;
    }
  }

  &:focus {
    outline: none;
  }

  &-success {
    background-color: #009688;
    color: #fff;
    padding: 5px 8px;
    border-radius: 3px;
    &:not([disabled]):hover {
      background-color: #02776b;
    }
  }

  &-danger {
    background-color: rgb(226, 53, 53);
    color: #fff;
    padding: 2px 5px;
    border-radius: 3px;
    &:not([disabled]):hover {
      background-color: rgb(163, 37, 37);
    }
  }
  &-info {
    background-color: rgb(53, 226, 217);
    color: #fff;
    padding: 2px 5px;
    border-radius: 3px;
    &:not([disabled]):hover {
      background-color: rgb(36, 165, 159);
    }
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
