* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $fontFamily;
  a {
    color: unset;
    text-decoration: none;
    cursor: pointer;
  }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primaryColor;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $seconderyColor;
}
